<template>
  <div
    class="
      component
      c-common-footer
      display-flex
      flex-direction-column
      bp-1024:flex-direction-row
      align-items-stretch
      justify-content-space-between
    "
    :class="dark_mode ? 'c-common-footer--black' : 'c-common-footer--white'"
    role="contentinfo"
  >
    <div class="c-common-footer__links">
      <ul
        class="
          c-common-footer__list-items
          display-flex
          flex-direction-column
          align-items-stretch
          bp-1024:flex-direction-row
          flex-wrap-wrap
        "
      >
        <li class="c-common-footer__list-item">
          <a
            class="c-common-footer__list-link"
            href="http://sidearmsports.com/terms-of-service"
            target="_blank"
            >Terms of Service</a
          >
        </li>
        <li class="c-common-footer__list-item">
          <a
            class="c-common-footer__list-link"
            href="http://sidearmsports.com/privacypolicy"
            target="_blank"
            >Privacy Policy</a
          >
        </li>

        <li class="c-common-footer__list-item">
          <a
            class="c-common-footer__list-link"
            href="https://www.cbssports.com"
            target="_blank"
            >video platform powered by CBS Sports Digital</a
          >
        </li>
      </ul>
    </div>
    <div class="c-common-footer__logos display-flex align-items-center">
      <div class="c-common-footer__logo-item">
        <a
          class="c-common-footer__logo-link"
          href="https://www.sidearmsports.com"
          aria-label="Sidearm Sports"
          target="_blank"
        >
          <svg
            v-if="dark_mode"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 767 155"
            aria-labelledby="sidearm_sports_logo_title"
          >
            <title id="sidearm_sports_logo_title">
              Sidearm Sports Learfield IMG College
            </title>
            <defs>
              <path id="qv4ia" d="M0 109.53h766.178v-3.6H0z">
                <path
                  id="qv4ib"
                  d="M483.097 13.744c-2.332 0-3.499 2.119-3.499 6.364v2.51c0 3.249 2.359 6.256 7.141 9.065 6.656 3.929 11.067 7.143 13.256 9.62 4.848 5.61 7.267 13.317 7.267 23.156 0 13.841-8.1 20.767-24.282 20.767-8.001 0-13.769-1.479-17.224-4.441-3.73-3.258-5.566-8.799-5.566-16.667V54.007h19.502c0 .931-.067 2.329-.225 4.214-.145 1.891-.227 3.279-.227 4.192 0 6.57 1.329 9.863 3.971 9.863 2.664 0 3.957-2.542 3.957-7.595 0-3.471-.429-6.182-1.344-8.113-.903-1.923-2.786-3.826-5.637-5.709-7.535-4.991-11.861-8.104-12.997-9.308-4.663-4.998-7-11.877-7-20.65C460.19 7.504 467.85.82 483.151.82c9.448 0 15.679 1.978 18.788 5.903 2.642 3.319 3.957 9.825 3.957 19.508h-18.728v-4.993c0-5.003-1.344-7.494-4.071-7.494"
                >
                  <path
                    id="qv4ic"
                    d="M511.29.82h27.488c13.305 0 19.979 6.443 19.979 19.271v10.394c0 9.156-2.45 15.052-7.342 17.733-3.157 1.734-9.841 2.604-20.1 2.604v34.404H511.29V.82zm20.025 35.813h3.786c3.464 0 5.183-1.706 5.183-5.093V20.09c0-3.394-1.72-5.072-5.183-5.072h-3.786z"
                  >
                    <path
                      id="qv4id"
                      d="M562.79 25.892c0-8.543 2.023-14.849 5.996-18.942 4.009-4.1 10.303-6.13 18.843-6.13 8.555 0 14.821 2.03 18.823 6.13 3.997 4.093 6.02 10.399 6.02 18.942v34.243c0 8.553-2.023 14.867-6.02 18.953-4.002 4.077-10.268 6.138-18.823 6.138-8.54 0-14.834-2.061-18.843-6.138-3.973-4.086-5.996-10.4-5.996-18.953V25.892zm20.868 40.375c0 3.556 1.323 5.333 3.976 5.333 2.655 0 3.979-1.777 3.979-5.333V19.774c0-3.56-1.324-5.335-3.979-5.335-2.653 0-3.976 1.775-3.976 5.335z"
                    >
                      <path
                        id="qv4ie"
                        d="M617.51.82h23.008c10.496 0 17.213 1.497 20.222 4.485 2.997 2.996 4.49 9.741 4.49 20.222 0 4.739-.964 8.246-2.889 10.574-1.935 2.331-5.24 3.772-9.898 4.315 4.985.641 8.494 2.101 10.537 4.366 2.063 2.3 3.075 5.922 3.075 10.876v29.568h-20.331V53.184c0-4.875-1.845-7.332-5.557-7.332h-2.594v39.374H617.51V.82zm20.063 32.256h1.645c4.326 0 6.496-1.945 6.496-5.899v-6.981c0-3.927-2.17-5.906-6.496-5.906h-1.645z"
                      >
                        <path
                          id="qv4if"
                          d="M680.632 17.847H667.74V.82h47.53v17.027h-12.893v67.379h-21.745V17.847"
                        >
                          <path
                            id="qv4ig"
                            d="M740.852 13.745c-2.339 0-3.528 2.119-3.528 6.363v2.51c0 3.248 2.389 6.255 7.151 9.065 6.644 3.928 11.061 7.143 13.27 9.62 4.833 5.611 7.246 13.316 7.246 23.155 0 13.842-8.074 20.768-24.272 20.768-7.998 0-13.744-1.479-17.251-4.441-3.685-3.259-5.538-8.799-5.538-16.667v-10.11h19.514c0 .929-.087 2.329-.228 4.214-.15 1.889-.217 3.278-.217 4.191 0 6.571 1.289 9.864 3.961 9.864 2.648 0 3.974-2.544 3.974-7.594 0-3.474-.459-6.182-1.378-8.114-.885-1.923-2.788-3.828-5.62-5.711-7.544-4.991-11.858-8.104-12.989-9.307-4.685-4.998-7.017-11.877-7.017-20.649C717.93 7.504 725.585.82 740.891.82c9.435 0 15.685 1.979 18.786 5.903 2.66 3.32 3.954 9.827 3.954 19.508h-18.697v-4.994c0-5.003-1.378-7.492-4.082-7.492"
                          >
                            <path
                              id="qv4ih"
                              d="M115.159 13.719c-2.444 0-3.667 2.112-3.667 6.334v2.49c0 3.245 2.48 6.263 7.434 9.05 6.922 3.923 11.523 7.128 13.804 9.616 5.033 5.587 7.552 13.278 7.552 23.081 0 13.801-8.418 20.705-25.25 20.705-8.336 0-14.315-1.473-17.933-4.411-3.854-3.242-5.779-8.791-5.779-16.635V53.882h20.29c0 .906-.077 2.301-.234 4.187-.159 1.887-.237 3.279-.237 4.185 0 6.56 1.375 9.841 4.13 9.841 2.753 0 4.128-2.524 4.128-7.58 0-3.467-.468-6.164-1.41-8.087-.939-1.924-2.898-3.83-5.873-5.714-7.834-4.978-12.339-8.074-13.514-9.278-4.854-4.98-7.28-11.84-7.28-20.59C91.32 7.495 99.282.82 115.21.82c9.808 0 16.319 1.964 19.538 5.885 2.744 3.317 4.117 9.802 4.117 19.458h-19.468v-4.978c0-4.981-1.412-7.466-4.238-7.466"
                            >
                              <path
                                id="qv4ii"
                                d="M145.48 85.224h21.692V1.05H145.48z"
                              >
                                <path
                                  id="qv4ij"
                                  d="M173.41 1.05h27.352c8.565 0 14.617 1.415 18.154 4.244 4.086 3.222 6.132 9.001 6.132 17.33v46.45c0 10.768-6.408 16.15-19.217 16.15H173.41V1.05zm21.687 70.026h3.19c3.387 0 5.083-2.317 5.083-6.956V22.154c0-4.636-1.696-6.957-5.082-6.957h-3.191z"
                                >
                                  <path
                                    id="qv4ik"
                                    d="M253.017 68.248V49.74h18.368l2.051-16.27h-20.419V18.026h22.366l2.14-16.976H225.94s5.137 2.89 5.385 7.448v69.281c-.248 4.557-5.385 7.445-5.385 7.445h40.973l2.139-16.976h-16.035"
                                  >
                                    <path
                                      id="qv4il"
                                      d="M283.76 1.05h31.241l11.555 84.174h-20.583L303.8 62.526h-7.896l-2.374 22.698h-20.38L283.76 1.05zm19.695 48.562l-3.775-33.706-3.776 33.706z"
                                    >
                                      <path
                                        id="qv4im"
                                        d="M321.24 1.05h30.162c10.45 0 17.173 1.495 20.16 4.48 2.987 2.986 4.48 9.706 4.48 20.158 0 4.719-.965 8.233-2.889 10.551-1.927 2.322-5.208 3.753-9.841 4.304 4.948.629 8.446 2.084 10.49 4.362 2.044 2.28 3.066 5.895 3.066 10.846v29.473h-20.279V57.391c0-4.872-1.845-7.785-5.54-7.785h-2.593v35.618h-15.859L321.24 1.05zm27.211 32.168h1.65c4.328 0 6.492-1.96 6.492-5.889V20.38c0-3.926-2.164-5.89-6.492-5.89h-1.65z"
                                      >
                                        <path
                                          id="qv4in"
                                          d="M382.1 1.05h28.885l5.658 47.545 5.302-47.545h29.239v84.174h-18.391V22.74l-9.381 62.484h-13.775l-9.144-62.484v62.484H382.1V1.05"
                                        >
                                          <path
                                            id="qv4io"
                                            d="M25.3 67.022l17.971 17.971 25.089-25.09-8.443-8.443L25.3 67.022"
                                          >
                                            <path
                                              id="qv4ip"
                                              d="M18.18 25.91l8.443 8.442L61.24 18.791 43.269.82 18.18 25.91"
                                            >
                                              <path
                                                id="qv4iq"
                                                d="M25.045 41.122L14.012 30.088 1.19 42.91l19.94 19.941 40.379-18.152 11.034 11.033L85.365 42.91 65.424 22.97 25.045 41.122"
                                              >
                                                <path
                                                  id="qv4ir"
                                                  d="M60.89 127.38h4.103v19.618h12.278v3.736H60.89V127.38"
                                                >
                                                  <path
                                                    id="qv4is"
                                                    d="M107.175 131.05H93.963v6.073h11.711v3.669H93.963v6.272h13.379v3.67H89.86V127.38h17.315v3.67"
                                                  >
                                                    <path
                                                      id="qv4it"
                                                      d="M142.884 150.731h-4.338l-2.369-5.639h-11.042l-2.402 5.639h-4.203l10.275-23.521h3.804l10.275 23.521zm-12.245-18.616l-4.003 9.341h8.04z"
                                                    >
                                                      <path
                                                        id="qv4iu"
                                                        d="M170.189 150.734l-5.739-8.14h-5.137v8.14h-4.103V127.38h10.409c5.371 0 8.673 2.835 8.673 7.408 0 3.87-2.301 6.205-5.571 7.106l6.306 8.84h-4.838zm-4.904-19.617h-5.972v7.841h6.005c2.936 0 4.805-1.536 4.805-3.937 0-2.569-1.802-3.904-4.838-3.904z"
                                                      >
                                                        <path
                                                          id="qv4iv"
                                                          d="M205.031 131.117h-13.278v6.372h11.777v3.737h-11.777v9.508h-4.103V127.38h17.381v3.737"
                                                        >
                                                          <path
                                                            id="qv4iw"
                                                            d="M218.09 150.734h4.104V127.38h-4.104z"
                                                          >
                                                            <path
                                                              id="qv4ix"
                                                              d="M254.056 131.05h-13.212v6.073h11.71v3.669h-11.71v6.272h13.379v3.67H236.74V127.38h17.316v3.67"
                                                            >
                                                              <path
                                                                id="qv4iy"
                                                                d="M267.42 127.38h4.103v19.618h12.278v3.736H267.42V127.38"
                                                              >
                                                                <path
                                                                  id="qv4iz"
                                                                  d="M305.088 150.734h-8.708V127.38h8.708c7.339 0 12.411 5.039 12.411 11.644 0 6.606-5.072 11.71-12.411 11.71zm.004-19.617h-4.608v15.881h4.608c4.907 0 8.113-3.303 8.113-7.908 0-4.604-3.206-7.973-8.113-7.973z"
                                                                >
                                                                  <path
                                                                    id="qv4iA"
                                                                    d="M350.02 154.943h3.237V124.11h-3.237z"
                                                                  >
                                                                    <path
                                                                      id="qv4iB"
                                                                      d="M387.03 150.734h4.104V127.38h-4.104z"
                                                                    >
                                                                      <path
                                                                        id="qv4iC"
                                                                        d="M417.166 144.997h-.134l-7.305-10.944v16.681h-4.037V127.38h4.371l7.105 11.044 7.107-11.044h4.371v23.354h-4.105v-16.747l-7.373 11.01"
                                                                      >
                                                                        <path
                                                                          id="qv4iD"
                                                                          d="M454.041 151.145c-7.307 0-12.111-5.207-12.111-12.05 0-6.575 5.005-12.115 12.045-12.115 4.069 0 6.571 1.135 8.973 3.17l-2.602 3.105c-1.801-1.536-3.57-2.502-6.538-2.502-4.304 0-7.574 3.736-7.574 8.276 0 4.84 3.169 8.377 7.941 8.377 2.201 0 4.203-.7 5.638-1.768v-4.372h-5.972v-3.572h9.942v9.813c-2.302 1.968-5.606 3.638-9.742 3.638"
                                                                        >
                                                                          <path
                                                                            id="qv4iE"
                                                                            d="M506.608 151.145c-6.807 0-11.878-5.307-11.878-12.05 0-6.674 4.971-12.115 12.045-12.115 4.303 0 6.905 1.502 9.14 3.638l-2.636 3.038c-1.868-1.736-3.87-2.903-6.539-2.903-4.469 0-7.707 3.67-7.707 8.276s3.238 8.345 7.707 8.345c2.87 0 4.704-1.169 6.706-3.038l2.636 2.67c-2.435 2.536-5.104 4.139-9.474 4.139"
                                                                          >
                                                                            <path
                                                                              id="qv4iF"
                                                                              d="M539.187 151.145c-7.172 0-12.177-5.44-12.177-12.05 0-6.607 5.071-12.115 12.243-12.115 7.175 0 12.178 5.441 12.178 12.049 0 6.609-5.071 12.116-12.244 12.116zm0-20.396c-4.637 0-7.872 3.704-7.872 8.275 0 4.57 3.302 8.344 7.938 8.344 4.639 0 7.875-3.706 7.875-8.278 0-4.57-3.304-8.34-7.941-8.34z"
                                                                            >
                                                                              <path
                                                                                id="qv4iG"
                                                                                d="M564.72 127.38h4.104v19.618h12.278v3.736H564.72V127.38"
                                                                              >
                                                                                <path
                                                                                  id="qv4iH"
                                                                                  d="M593.69 127.38h4.103v19.618h12.279v3.736H593.69V127.38"
                                                                                >
                                                                                  <path
                                                                                    id="qv4iI"
                                                                                    d="M639.976 131.05h-13.212v6.073h11.71v3.669h-11.71v6.272h13.379v3.67H622.66V127.38h17.316v3.67"
                                                                                  >
                                                                                    <path
                                                                                      id="qv4iJ"
                                                                                      d="M664.401 151.145c-7.307 0-12.111-5.207-12.111-12.05 0-6.575 5.005-12.115 12.045-12.115 4.069 0 6.572 1.135 8.974 3.17l-2.603 3.105c-1.801-1.536-3.57-2.502-6.538-2.502-4.305 0-7.573 3.736-7.573 8.276 0 4.84 3.17 8.377 7.939 8.377 2.201 0 4.204-.7 5.639-1.768v-4.372H664.2v-3.572h9.944v9.813c-2.303 1.968-5.606 3.638-9.743 3.638"
                                                                                    >
                                                                                      <path
                                                                                        id="qv4iK"
                                                                                        d="M705.125 131.05h-13.212v6.073h11.71v3.669h-11.71v6.272h13.379v3.67H687.81V127.38h17.315v3.67"
                                                                                      ></path>
                                                                                    </path>
                                                                                  </path>
                                                                                </path>
                                                                              </path>
                                                                            </path>
                                                                          </path>
                                                                        </path>
                                                                      </path>
                                                                    </path>
                                                                  </path>
                                                                </path>
                                                              </path>
                                                            </path>
                                                          </path>
                                                        </path>
                                                      </path>
                                                    </path>
                                                  </path>
                                                </path>
                                              </path>
                                            </path>
                                          </path>
                                        </path>
                                      </path>
                                    </path>
                                  </path>
                                </path>
                              </path>
                            </path>
                          </path>
                        </path>
                      </path>
                    </path>
                  </path>
                </path>
              </path>
            </defs>
            <g>
              <g>
                <g><use fill="#fff" xlink:href="#qv4ia"></use></g>
                <g><use fill="#e87722" xlink:href="#qv4ib"></use></g>
                <g><use fill="#e87722" xlink:href="#qv4ic"></use></g>
                <g><use fill="#e87722" xlink:href="#qv4id"></use></g>
                <g><use fill="#e87722" xlink:href="#qv4ie"></use></g>
                <g><use fill="#e87722" xlink:href="#qv4if"></use></g>
                <g><use fill="#e87722" xlink:href="#qv4ig"></use></g>
                <g><use fill="#fff" xlink:href="#qv4ih"></use></g>
                <g><use fill="#fff" xlink:href="#qv4ii"></use></g>
                <g><use fill="#fff" xlink:href="#qv4ij"></use></g>
                <g><use fill="#fff" xlink:href="#qv4ik"></use></g>
                <g><use fill="#fff" xlink:href="#qv4il"></use></g>
                <g><use fill="#fff" xlink:href="#qv4im"></use></g>
                <g><use fill="#fff" xlink:href="#qv4in"></use></g>
                <g><use fill="#fff" xlink:href="#qv4io"></use></g>
                <g><use fill="#fff" xlink:href="#qv4ip"></use></g>
                <g><use fill="#e87722" xlink:href="#qv4iq"></use></g>
                <g><use fill="#fff" xlink:href="#qv4ir"></use></g>
                <g><use fill="#fff" xlink:href="#qv4is"></use></g>
                <g><use fill="#fff" xlink:href="#qv4it"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iu"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iv"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iw"></use></g>
                <g><use fill="#fff" xlink:href="#qv4ix"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iy"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iz"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iA"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iB"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iC"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iD"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iE"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iF"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iG"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iH"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iI"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iJ"></use></g>
                <g><use fill="#fff" xlink:href="#qv4iK"></use></g>
              </g>
            </g>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 767 155"
            aria-labelledby="sidearm_sports_logo_title"
          >
            <title id="sidearm_sports_logo_title">
              Sidearm Sports Learfield IMG College
            </title>
            <defs>
              <path id="8q52a" d="M0 109.53h766.178v-3.6H0z">
                <path
                  id="8q52b"
                  d="M483.097 13.744c-2.332 0-3.499 2.119-3.499 6.364v2.51c0 3.249 2.359 6.256 7.141 9.065 6.656 3.929 11.067 7.143 13.256 9.62 4.848 5.61 7.267 13.317 7.267 23.156 0 13.841-8.1 20.767-24.282 20.767-8.001 0-13.769-1.479-17.224-4.441-3.73-3.258-5.566-8.799-5.566-16.667V54.007h19.502c0 .931-.067 2.329-.225 4.214-.145 1.891-.227 3.279-.227 4.192 0 6.57 1.329 9.863 3.971 9.863 2.664 0 3.957-2.542 3.957-7.595 0-3.471-.429-6.182-1.344-8.113-.903-1.923-2.786-3.826-5.637-5.709-7.535-4.991-11.861-8.104-12.997-9.308-4.663-4.998-7-11.877-7-20.65C460.19 7.504 467.85.82 483.151.82c9.448 0 15.679 1.978 18.788 5.903 2.642 3.319 3.957 9.825 3.957 19.508h-18.728v-4.993c0-5.003-1.344-7.494-4.071-7.494"
                >
                  <path
                    id="8q52c"
                    d="M511.29.82h27.488c13.305 0 19.979 6.443 19.979 19.271v10.394c0 9.156-2.45 15.052-7.342 17.733-3.157 1.734-9.841 2.604-20.1 2.604v34.404H511.29V.82zm20.025 35.813h3.786c3.464 0 5.183-1.706 5.183-5.093V20.09c0-3.394-1.72-5.072-5.183-5.072h-3.786z"
                  >
                    <path
                      id="8q52d"
                      d="M562.79 25.892c0-8.543 2.023-14.849 5.996-18.942 4.009-4.1 10.303-6.13 18.843-6.13 8.555 0 14.821 2.03 18.823 6.13 3.997 4.093 6.02 10.399 6.02 18.942v34.243c0 8.553-2.023 14.867-6.02 18.953-4.002 4.077-10.268 6.138-18.823 6.138-8.54 0-14.834-2.061-18.843-6.138-3.973-4.086-5.996-10.4-5.996-18.953V25.892zm20.868 40.375c0 3.556 1.323 5.333 3.976 5.333 2.655 0 3.979-1.777 3.979-5.333V19.774c0-3.56-1.324-5.335-3.979-5.335-2.653 0-3.976 1.775-3.976 5.335z"
                    >
                      <path
                        id="8q52e"
                        d="M617.51.82h23.008c10.496 0 17.213 1.497 20.222 4.485 2.997 2.996 4.49 9.741 4.49 20.222 0 4.739-.964 8.246-2.889 10.574-1.935 2.331-5.24 3.772-9.898 4.315 4.985.641 8.494 2.101 10.537 4.366 2.063 2.3 3.075 5.922 3.075 10.876v29.568h-20.331V53.184c0-4.875-1.845-7.332-5.557-7.332h-2.594v39.374H617.51V.82zm20.063 32.256h1.645c4.326 0 6.496-1.945 6.496-5.899v-6.981c0-3.927-2.17-5.906-6.496-5.906h-1.645z"
                      >
                        <path
                          id="8q52f"
                          d="M680.632 17.847H667.74V.82h47.53v17.027h-12.893v67.379h-21.745V17.847"
                        >
                          <path
                            id="8q52g"
                            d="M740.852 13.745c-2.339 0-3.528 2.119-3.528 6.363v2.51c0 3.248 2.389 6.255 7.151 9.065 6.644 3.928 11.061 7.143 13.27 9.62 4.833 5.611 7.246 13.316 7.246 23.155 0 13.842-8.074 20.768-24.272 20.768-7.998 0-13.744-1.479-17.251-4.441-3.685-3.259-5.538-8.799-5.538-16.667v-10.11h19.514c0 .929-.087 2.329-.228 4.214-.15 1.889-.217 3.278-.217 4.191 0 6.571 1.289 9.864 3.961 9.864 2.648 0 3.974-2.544 3.974-7.594 0-3.474-.459-6.182-1.378-8.114-.885-1.923-2.788-3.828-5.62-5.711-7.544-4.991-11.858-8.104-12.989-9.307-4.685-4.998-7.017-11.877-7.017-20.649C717.93 7.504 725.585.82 740.891.82c9.435 0 15.685 1.979 18.786 5.903 2.66 3.32 3.954 9.827 3.954 19.508h-18.697v-4.994c0-5.003-1.378-7.492-4.082-7.492"
                          >
                            <path
                              id="8q52h"
                              d="M115.159 13.719c-2.444 0-3.667 2.112-3.667 6.334v2.49c0 3.245 2.48 6.263 7.434 9.05 6.922 3.923 11.523 7.128 13.804 9.616 5.033 5.587 7.552 13.278 7.552 23.081 0 13.801-8.418 20.705-25.25 20.705-8.336 0-14.315-1.473-17.933-4.411-3.854-3.242-5.779-8.791-5.779-16.635V53.882h20.29c0 .906-.077 2.301-.234 4.187-.159 1.887-.237 3.279-.237 4.185 0 6.56 1.375 9.841 4.13 9.841 2.753 0 4.128-2.524 4.128-7.58 0-3.467-.468-6.164-1.41-8.087-.939-1.924-2.898-3.83-5.873-5.714-7.834-4.978-12.339-8.074-13.514-9.278-4.854-4.98-7.28-11.84-7.28-20.59C91.32 7.495 99.282.82 115.21.82c9.808 0 16.319 1.964 19.538 5.885 2.744 3.317 4.117 9.802 4.117 19.458h-19.468v-4.978c0-4.981-1.412-7.466-4.238-7.466"
                            >
                              <path
                                id="8q52i"
                                d="M145.48 85.224h21.692V1.05H145.48z"
                              >
                                <path
                                  id="8q52j"
                                  d="M173.41 1.05h27.352c8.565 0 14.617 1.415 18.154 4.244 4.086 3.222 6.132 9.001 6.132 17.33v46.45c0 10.768-6.408 16.15-19.217 16.15H173.41V1.05zm21.687 70.026h3.19c3.387 0 5.083-2.317 5.083-6.956V22.154c0-4.636-1.696-6.957-5.082-6.957h-3.191z"
                                >
                                  <path
                                    id="8q52k"
                                    d="M253.017 68.248V49.74h18.368l2.051-16.27h-20.419V18.026h22.366l2.14-16.976H225.94s5.137 2.89 5.385 7.448v69.281c-.248 4.557-5.385 7.445-5.385 7.445h40.973l2.139-16.976h-16.035"
                                  >
                                    <path
                                      id="8q52l"
                                      d="M283.76 1.05h31.241l11.555 84.174h-20.583L303.8 62.526h-7.896l-2.374 22.698h-20.38L283.76 1.05zm19.695 48.562l-3.775-33.706-3.776 33.706z"
                                    >
                                      <path
                                        id="8q52m"
                                        d="M321.24 1.05h30.162c10.45 0 17.173 1.495 20.16 4.48 2.987 2.986 4.48 9.706 4.48 20.158 0 4.719-.965 8.233-2.889 10.551-1.927 2.322-5.208 3.753-9.841 4.304 4.948.629 8.446 2.084 10.49 4.362 2.044 2.28 3.066 5.895 3.066 10.846v29.473h-20.279V57.391c0-4.872-1.845-7.785-5.54-7.785h-2.593v35.618h-15.859L321.24 1.05zm27.211 32.168h1.65c4.328 0 6.492-1.96 6.492-5.889V20.38c0-3.926-2.164-5.89-6.492-5.89h-1.65z"
                                      >
                                        <path
                                          id="8q52n"
                                          d="M382.1 1.05h28.885l5.658 47.545 5.302-47.545h29.239v84.174h-18.391V22.74l-9.381 62.484h-13.775l-9.144-62.484v62.484H382.1V1.05"
                                        >
                                          <path
                                            id="8q52o"
                                            d="M25.3 67.022l17.971 17.971 25.089-25.09-8.443-8.443L25.3 67.022"
                                          >
                                            <path
                                              id="8q52p"
                                              d="M18.18 25.91l8.443 8.442L61.24 18.791 43.269.82 18.18 25.91"
                                            >
                                              <path
                                                id="8q52q"
                                                d="M25.045 41.122L14.012 30.088 1.19 42.91l19.94 19.941 40.379-18.152 11.034 11.033L85.365 42.91 65.424 22.97 25.045 41.122"
                                              >
                                                <path
                                                  id="8q52r"
                                                  d="M60.89 127.38h4.103v19.618h12.278v3.736H60.89V127.38"
                                                >
                                                  <path
                                                    id="8q52s"
                                                    d="M107.175 131.05H93.963v6.073h11.711v3.669H93.963v6.272h13.379v3.67H89.86V127.38h17.315v3.67"
                                                  >
                                                    <path
                                                      id="8q52t"
                                                      d="M142.884 150.731h-4.338l-2.369-5.639h-11.042l-2.402 5.639h-4.203l10.275-23.521h3.804l10.275 23.521zm-12.245-18.616l-4.003 9.341h8.04z"
                                                    >
                                                      <path
                                                        id="8q52u"
                                                        d="M170.189 150.734l-5.739-8.14h-5.137v8.14h-4.103V127.38h10.409c5.371 0 8.673 2.835 8.673 7.408 0 3.87-2.301 6.205-5.571 7.106l6.306 8.84h-4.838zm-4.904-19.617h-5.972v7.841h6.005c2.936 0 4.805-1.536 4.805-3.937 0-2.569-1.802-3.904-4.838-3.904z"
                                                      >
                                                        <path
                                                          id="8q52v"
                                                          d="M205.031 131.117h-13.278v6.372h11.777v3.737h-11.777v9.508h-4.103V127.38h17.381v3.737"
                                                        >
                                                          <path
                                                            id="8q52w"
                                                            d="M218.09 150.734h4.104V127.38h-4.104z"
                                                          >
                                                            <path
                                                              id="8q52x"
                                                              d="M254.056 131.05h-13.212v6.073h11.71v3.669h-11.71v6.272h13.379v3.67H236.74V127.38h17.316v3.67"
                                                            >
                                                              <path
                                                                id="8q52y"
                                                                d="M267.42 127.38h4.103v19.618h12.278v3.736H267.42V127.38"
                                                              >
                                                                <path
                                                                  id="8q52z"
                                                                  d="M305.088 150.734h-8.708V127.38h8.708c7.339 0 12.411 5.039 12.411 11.644 0 6.606-5.072 11.71-12.411 11.71zm.004-19.617h-4.608v15.881h4.608c4.907 0 8.113-3.303 8.113-7.908 0-4.604-3.206-7.973-8.113-7.973z"
                                                                >
                                                                  <path
                                                                    id="8q52A"
                                                                    d="M350.02 154.943h3.237V124.11h-3.237z"
                                                                  >
                                                                    <path
                                                                      id="8q52B"
                                                                      d="M387.03 150.734h4.104V127.38h-4.104z"
                                                                    >
                                                                      <path
                                                                        id="8q52C"
                                                                        d="M417.166 144.997h-.134l-7.305-10.944v16.681h-4.037V127.38h4.371l7.105 11.044 7.107-11.044h4.371v23.354h-4.105v-16.747l-7.373 11.01"
                                                                      >
                                                                        <path
                                                                          id="8q52D"
                                                                          d="M454.041 151.145c-7.307 0-12.111-5.207-12.111-12.05 0-6.575 5.005-12.115 12.045-12.115 4.069 0 6.571 1.135 8.973 3.17l-2.602 3.105c-1.801-1.536-3.57-2.502-6.538-2.502-4.304 0-7.574 3.736-7.574 8.276 0 4.84 3.169 8.377 7.941 8.377 2.201 0 4.203-.7 5.638-1.768v-4.372h-5.972v-3.572h9.942v9.813c-2.302 1.968-5.606 3.638-9.742 3.638"
                                                                        >
                                                                          <path
                                                                            id="8q52E"
                                                                            d="M506.608 151.145c-6.807 0-11.878-5.307-11.878-12.05 0-6.674 4.971-12.115 12.045-12.115 4.303 0 6.905 1.502 9.14 3.638l-2.636 3.038c-1.868-1.736-3.87-2.903-6.539-2.903-4.469 0-7.707 3.67-7.707 8.276s3.238 8.345 7.707 8.345c2.87 0 4.704-1.169 6.706-3.038l2.636 2.67c-2.435 2.536-5.104 4.139-9.474 4.139"
                                                                          >
                                                                            <path
                                                                              id="8q52F"
                                                                              d="M539.187 151.145c-7.172 0-12.177-5.44-12.177-12.05 0-6.607 5.071-12.115 12.243-12.115 7.175 0 12.178 5.441 12.178 12.049 0 6.609-5.071 12.116-12.244 12.116zm0-20.396c-4.637 0-7.872 3.704-7.872 8.275 0 4.57 3.302 8.344 7.938 8.344 4.639 0 7.875-3.706 7.875-8.278 0-4.57-3.304-8.34-7.941-8.34z"
                                                                            >
                                                                              <path
                                                                                id="8q52G"
                                                                                d="M564.72 127.38h4.104v19.618h12.278v3.736H564.72V127.38"
                                                                              >
                                                                                <path
                                                                                  id="8q52H"
                                                                                  d="M593.69 127.38h4.103v19.618h12.279v3.736H593.69V127.38"
                                                                                >
                                                                                  <path
                                                                                    id="8q52I"
                                                                                    d="M639.976 131.05h-13.212v6.073h11.71v3.669h-11.71v6.272h13.379v3.67H622.66V127.38h17.316v3.67"
                                                                                  >
                                                                                    <path
                                                                                      id="8q52J"
                                                                                      d="M664.401 151.145c-7.307 0-12.111-5.207-12.111-12.05 0-6.575 5.005-12.115 12.045-12.115 4.069 0 6.572 1.135 8.974 3.17l-2.603 3.105c-1.801-1.536-3.57-2.502-6.538-2.502-4.305 0-7.573 3.736-7.573 8.276 0 4.84 3.17 8.377 7.939 8.377 2.201 0 4.204-.7 5.639-1.768v-4.372H664.2v-3.572h9.944v9.813c-2.303 1.968-5.606 3.638-9.743 3.638"
                                                                                    >
                                                                                      <path
                                                                                        id="8q52K"
                                                                                        d="M705.125 131.05h-13.212v6.073h11.71v3.669h-11.71v6.272h13.379v3.67H687.81V127.38h17.315v3.67"
                                                                                      ></path>
                                                                                    </path>
                                                                                  </path>
                                                                                </path>
                                                                              </path>
                                                                            </path>
                                                                          </path>
                                                                        </path>
                                                                      </path>
                                                                    </path>
                                                                  </path>
                                                                </path>
                                                              </path>
                                                            </path>
                                                          </path>
                                                        </path>
                                                      </path>
                                                    </path>
                                                  </path>
                                                </path>
                                              </path>
                                            </path>
                                          </path>
                                        </path>
                                      </path>
                                    </path>
                                  </path>
                                </path>
                              </path>
                            </path>
                          </path>
                        </path>
                      </path>
                    </path>
                  </path>
                </path>
              </path>
            </defs>
            <g>
              <g><use fill="#003057" xlink:href="#8q52a"></use></g>
              <g><use fill="#e87722" xlink:href="#8q52b"></use></g>
              <g><use fill="#e87722" xlink:href="#8q52c"></use></g>
              <g><use fill="#e87722" xlink:href="#8q52d"></use></g>
              <g><use fill="#e87722" xlink:href="#8q52e"></use></g>
              <g><use fill="#e87722" xlink:href="#8q52f"></use></g>
              <g><use fill="#e87722" xlink:href="#8q52g"></use></g>
              <g><use fill="#003057" xlink:href="#8q52h"></use></g>
              <g><use fill="#003057" xlink:href="#8q52i"></use></g>
              <g><use fill="#003057" xlink:href="#8q52j"></use></g>
              <g><use fill="#003057" xlink:href="#8q52k"></use></g>
              <g><use fill="#003057" xlink:href="#8q52l"></use></g>
              <g><use fill="#003057" xlink:href="#8q52m"></use></g>
              <g><use fill="#003057" xlink:href="#8q52n"></use></g>
              <g><use fill="#003057" xlink:href="#8q52o"></use></g>
              <g><use fill="#003057" xlink:href="#8q52p"></use></g>
              <g><use fill="#e87722" xlink:href="#8q52q"></use></g>
              <g><use fill="#003057" xlink:href="#8q52r"></use></g>
              <g><use fill="#003057" xlink:href="#8q52s"></use></g>
              <g><use fill="#003057" xlink:href="#8q52t"></use></g>
              <g><use fill="#003057" xlink:href="#8q52u"></use></g>
              <g><use fill="#003057" xlink:href="#8q52v"></use></g>
              <g><use fill="#003057" xlink:href="#8q52w"></use></g>
              <g><use fill="#003057" xlink:href="#8q52x"></use></g>
              <g><use fill="#003057" xlink:href="#8q52y"></use></g>
              <g><use fill="#003057" xlink:href="#8q52z"></use></g>
              <g><use fill="#003057" xlink:href="#8q52A"></use></g>
              <g><use fill="#003057" xlink:href="#8q52B"></use></g>
              <g><use fill="#003057" xlink:href="#8q52C"></use></g>
              <g><use fill="#003057" xlink:href="#8q52D"></use></g>
              <g><use fill="#003057" xlink:href="#8q52E"></use></g>
              <g><use fill="#003057" xlink:href="#8q52F"></use></g>
              <g><use fill="#003057" xlink:href="#8q52G"></use></g>
              <g><use fill="#003057" xlink:href="#8q52H"></use></g>
              <g><use fill="#003057" xlink:href="#8q52I"></use></g>
              <g><use fill="#003057" xlink:href="#8q52J"></use></g>
              <g><use fill="#003057" xlink:href="#8q52K"></use></g>
            </g>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  data: () => ({}),
  computed: {
    dark_mode() {
      if (
        this.$root.gameday &&
        this.$root.gameday.selectedComponents &&
        this.$root.gameday.selectedComponents.length
      ) {
        return this.$root.gameday.selectedComponents[
          this.$root.gameday.selectedComponents.length - 1
        ].dark_mode;
      } else return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-common-footer {
  line-height: 1.5;
  padding: 0;
}
.c-common-footer .c-common-footer__list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-common-footer .c-common-footer__list-item {
  padding: 12px 24px;
  border-top: solid 1px;
}
.c-common-footer .c-common-footer__list-item:first-child {
  border-top: none;
}
.c-common-footer .c-common-footer__list-item:nth-child(n + 3) {
  width: 100%;
}
.c-common-footer .c-common-footer__list-link {
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
}
.c-common-footer .c-common-footer__list-link:hover {
  text-decoration: underline;
}
.c-common-footer .c-common-footer__logos {
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  border-top: solid 1px;
}
.c-common-footer .c-common-footer__logo-item {
  padding: 24px;
  flex: 1 1 50%;
  -webkit-flex: 1 1 50%;
  text-align: center;
}
.c-common-footer .c-common-footer__logo-link {
  display: inline-block;
}
.c-common-footer .c-common-footer__logo-item:nth-child(n + 2) {
  border-left: solid 1px;
}
.c-common-footer .c-common-footer__logo-item svg {
  max-height: 30px;
  width: 100%;
}

.c-common-footer--black {
  background: black;
}
.c-common-footer--black .c-common-footer__list-link {
  color: white;
}
.c-common-footer--black .c-common-footer__list-item {
  border-top-color: #474952;
}
.c-common-footer--black .c-common-footer__list-item:nth-child(2) {
  border-left-color: #474952;
}
.c-common-footer--black .c-common-footer__logos {
  border-top-color: #474952;
}
.c-common-footer--black .c-common-footer__logo-item:nth-child(n + 2) {
  border-left-color: #474952;
}

.c-common-footer--white {
  background: white;
}
.c-common-footer--white .c-common-footer__list-link {
  color: black;
}
.c-common-footer--white .c-common-footer__list-item {
  border-top-color: #d9d9db;
}
.c-common-footer--white .c-common-footer__list-item:nth-child(2) {
  border-left-color: #d9d9db;
}
.c-common-footer--white .c-common-footer__logos {
  border-top-color: #d9d9db;
}
.c-common-footer--white .c-common-footer__logo-item:nth-child(n + 2) {
  border-left-color: #d9d9db;
}

.c-common-footer--gray {
  background: #f5f5f5;
}
.c-common-footer--gray .c-common-footer__list-link {
  color: black;
}
.c-common-footer--gray .c-common-footer__list-item {
  border-top-color: #d9d9db;
}
.c-common-footer--gray .c-common-footer__list-item:nth-child(2) {
  border-left-color: #d9d9db;
}
.c-common-footer--gray .c-common-footer__logos {
  border-top-color: #d9d9db;
}
.c-common-footer--gray .c-common-footer__logo-item:nth-child(n + 2) {
  border-left-color: #d9d9db;
}

@media all and (min-width: 1024px) {
  .c-common-footer .c-common-footer__links {
    padding: 24px 12px;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
  }
  .c-common-footer .c-common-footer__list-item {
    padding: 0 12px;
    margin-bottom: 4px;
    border-top: none;
  }
  .c-common-footer .c-common-footer__list-item:nth-child(2) {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .c-common-footer .c-common-footer__logos {
    border-top-style: none;
  }
  .c-common-footer .c-common-footer__logo-item {
    flex: 1 0 auto;
    -webkit-flex: 1 0 auto;
  }
  .c-common-footer .c-common-footer__logo-item svg {
    height: 36px;
    width: auto;
    max-height: none;
  }

  .c-common-footer--learfield .c-common-footer__logos {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .c-common-footer {
    padding: 0;
  }
  .c-common-footer .c-common-footer__logos {
    border-top: none;
  }
  .c-common-footer .c-common-footer__logo-item {
    padding: 6px 24px;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
  }
}
</style>
