<template>
  <div v-if="campaign && images.length" class="ad single-ad">
    <a
      :href="campaign.link"
      :target="campaign.open_in_new_window ? '_blank' : ''"
      :aria-label="alt_text"
    >
      <picture>
        <source
          v-for="(size, size_index) in images"
          :key="size_index"
          :srcset="size.image"
          :media="`(min-width: ${size.breakpoint}px)`"
        />

        <img :src="images[0].image" :alt="images[0].alt" />
      </picture>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },

    campaigns: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({}),

  computed: {
    campaign() {
      if (this.campaigns && this.campaigns.length) {
        return this.campaigns[0];
      }

      return null;
    },

    images() {
      if (
        this.campaign &&
        this.campaign.picture.images &&
        this.campaign.picture.images.length
      ) {
        return this.campaign.picture.images;
      }

      return [];
    },

    alt_text() {
      if (this.images.length) {
        return this.images[0].alt;
      }

      return this.campaign.title;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  display: inline-block;
  vertical-align: bottom;
}
</style>
