<template>
  <div id="app" :class="navigation_class">
    <component
      v-if="$root.navigation"
      :is="navigation_component"
      :current_section="active_section"
    />
    <router-view @change-section="update_section" />
    <TheFooter :dark_mode="$root.dark_mode" />
  </div>
</template>

<script>
import TheFooter from "./components/TheFooter";
import NavigationLeft from "./components/navigation/NavigationLeft.vue";
import NavigationMini from "./components/navigation/NavigationMini.vue";
import NavigationTop from "./components/navigation/NavigationTop.vue";
export default {
  name: "App",
  components: {
    NavigationMini,
    NavigationTop,
    NavigationLeft,
    TheFooter,
  },
  data: () => ({
    interval: null,
    active_section: null,
  }),
  computed: {
    navigation_component() {
      if (this.$root.navigation) {
        let _template = this.$root.navigation.templates.find((template) => {
          return template.selected;
        });
        var root = document.documentElement;
        root.className += _template.name;
        if (_template.name === "nav-1") {
          return "navigation-top";
        } else if (_template.name === "nav-2") {
          return "navigation-left";
        } else {
          return "navigation-mini";
        }
      }
      return null;
    },
    navigation_class() {
      if (this.$root.navigation) {
        let _template = this.$root.navigation.templates.find((template) => {
          return template.selected;
        });

        return _template.name;
      }
      return "";
    },
  },
  methods: {
    update_section(id) {
      this.active_section = id;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/app.scss";
</style>

<style lang="scss" scoped>
#app {
  overflow-anchor: none;
  &.nav-1 {
    padding-top: 72px;
  }
  &.nav-2 {
    padding-left: 120px;
    @media screen and (max-width: 1023px) {
      padding-top: 72px;
      padding-left: 0;
    }
  }
}
</style>
