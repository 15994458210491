import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
Vue.use(VueRouter);

// const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue');

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Gameday",
      metaTags: [
        {
          name: "description",
          content: "Check out Gameday",
        },
        {
          itemprop: "name",
          contnet: "Gameday ",
        },
        {
          itemprop: "description",
          content: "Check out  Gameday",
        },
        {
          name: "og:title",
          content: "Check out  Gameday",
        },
        {
          name: "og:description",
          content: "Check out  Gameday",
        },
        {
          name: "og:image",
          content: "/images/logos/site/site.png",
        },
        {
          name: "twitter:title",
          content: "Check out Gameday",
        },
        {
          name: "twitter:description",
          content: "Check out Gameday",
        },
        {
          name: "twitter:image",
          content: "/images/logos/site/site.png",
        },
      ],
    },
  },
  { path: "*", component: Home },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/gameday_demo/" : "/",
  routes,
});

export default router;
