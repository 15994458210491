<template>
  <div
    v-if="campaigns && campaigns.length"
    class="ad html-ad"
    :class="single.css_class"
    v-html="convertHTML(single.html)"
  ></div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },

    campaigns: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({}),
  methods: {
    convertHTML(html) {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
  },
  computed: {
    single() {
      return this.campaigns[0];
    },
  },
};
</script>
