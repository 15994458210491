<template>
  <div
    class="sidearm-ad ad"
    v-if="
      (campaigns && campaigns.length) || (location && location.type === 'dfp')
    "
  >
    <component
      v-if="location && location.type"
      :is="location.type"
      :name="name"
      :location="location"
      :campaigns="campaigns"
      @set_width="emit_width"
    />
  </div>
</template>

<script>
import Client from "@/client";
import ElementAdSingle from "@/components/elements/ads/ElementAdSingle.vue";
import ElementAdDfp from "@/components/elements/ads/ElementAdDfp.vue";
import ElementAdIcons from "@/components/elements/ads/ElementAdIcons.vue";
import ElementAdHtml from "@/components/elements/ads/ElementAdHtml.vue";

export default {
  props: {
    name: {
      type: String,
      required: true,
      default: "",
    },
  },

  components: {
    single: ElementAdSingle,
    dfp: ElementAdDfp,
    icons: ElementAdIcons,
    htmlAd: ElementAdHtml,
  },

  data: () => ({
    location: null,
    sport_id: 0,
    campaigns: [],
  }),

  methods: {
    emit_width(value) {
      this.$emit("set_width", value);
    },
    get_campaigns() {
      let request_options = {
        type: "ads",
        sport_id: this.sport_id,
        name: this.name,
      };
      Client.get("/services/adaptive_components.ashx", {
        params: request_options,
      }).then((response) => {
        let ad = response.data;
        let _location = ad.location;
        _location.type = _location.type === "html" ? "html-ad" : _location.type;
        this.location = _location;
        this.campaigns = ad.campaigns;
      });
    },
  },
  created() {
    this.get_campaigns();
  },
};
</script>
