<template>
  <div v-if="campaigns && campaigns.length" class="ads-container">
    <div
      class="ad icons-ad"
      v-for="campaign in campaigns"
      :key="campaign.id"
      :class="campaign.css_class"
    >
      <a
        :href="campaign.link"
        :target="campaign.open_in_new_window ? '_blank' : '_self'"
        :aria-label="campaign.title + '(' + campaign.id + ')'"
      >
        <span :class="campaign.icon"></span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },

    campaigns: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({}),
};
</script>
