import Velocity from "velocity-animate";

export default {
  name: "TheNavigation",
  props: {
    current_section: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    query_string:
      location.href.indexOf("?path=") > 0
        ? "?path=" + location.href.split("?path=")[1]
        : "",
    is_menu_open: false,
    site_url: window.location.host,
    icons: {
      hero: "home",
      contentstream: "zap",
      social: "zap",
      videos: "video",
      roster: "users",
      players: "users",
      schedule: "calendar",
      stories: "rss",
      galleries: "image",
    },
  }),
  computed: {
    continue_url() {
      let _url = this.$root.navigation.continueUrl
        ? this.$root.navigation.continueUrl
        : "";
      _url = _url.replace(/^\s+|\s+$/g, "");

      if (_url === "/" || _url === "/index.aspx" || _url === "") {
        _url = "/index.aspx" + this.query_string;
      }

      return _url;
    },
    continue_text() {
      return this.$root.navigation.continueText || "Continue to Full Site";
    },
    links() {
      let _links = [];
      this.$root.selected_components.forEach((component) => {
        if (component.menuInclude) {
          _links.push({
            title: component.headline,
            url: component.name + "-" + component.id,
            onPage: true,
            navMenuText: component.navMenuText || "",
            icon: component.name,
          });
        }
      });

      return _links;
    },
    more_links() {
      let _links = [];

      this.$root.navigation.links.forEach((link) => {
        _links.push({
          title: link.title,
          url: link.url,
          onPage: false,
        });
      });

      return _links;
    },
    logo_image() {
      if (
        this.$root.gameday &&
        this.$root.gameday.schoolTeam &&
        this.$root.gameday.schoolTeam.logo
      ) {
        return this.$root.gameday.schoolTeam.logo;
      }
      return "";
    },
    logo_title() {
      if (this.logo_image) {
        return this.$root.gameday.schoolTeam.title;
      }
      return "";
    },
  },

  methods: {
    toggle_menu() {
      this.is_menu_open = !this.is_menu_open;
    },
    scroll_to(anchor) {
      let _desktop_offset = this.desktop_offset ? this.desktop_offset : 0;
      let _mobile_offset = this.mobile_offset ? this.mobile_offset : 0;

      this.is_menu_open = false;
      let _offset = window.matchMedia("(max-width:1023px)").matches
        ? _mobile_offset
        : _desktop_offset;
      Velocity(document.getElementById(anchor), "scroll", {
        offset: _offset,
      });
      this.focus_on_first_element(document.getElementById(anchor));
    },
    focus_on_first_element(element = document) {
      let focusable_elements = [
        ...element.querySelectorAll(
          'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
        ),
      ].filter((el) => !el.hasAttribute("disabled"));

      if (focusable_elements.length) {
        focusable_elements[0].focus();
      }
    },
    close_menu() {
      this.is_menu_open = false;
      document.getElementsByClassName("c-navigation__menu-button")[0].focus();
    },
  },
};
