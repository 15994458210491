import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? `/`
    : `http://${process.env.VUE_APP_CORS_HOST}:${process.env.VUE_APP_CORS_PORT}/${process.env.VUE_APP_SIDEARM_INSTANCE_BASE_URL}`;

export default axios.create({
  baseURL: baseUrl,
});
