<template>
  <div
    :class="{
      'sidearm-dfp-ad': location && location.sidearm_dfp,
      'dfp-ad': location && !location.sidearm_dfp,
    }"
    class="text-center"
    ref="ad"
  >
    <div
      :style="{
        width: `${width}px`,
        height: height === 0 ? 'auto' : `${height}px`,
      }"
      :id="id"
      class="sidearm-dfp-ad__inner"
    ></div>
  </div>
</template>

<script>
import cuid from "cuid";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    location: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    pageTargetting:
      window.cbs_ads_activated === true && window.PageTargetting !== undefined
        ? window.PageTargetting.getPageLevel()
        : null,

    hasAssignedTopForWidth: {},

    unit_name: "",

    network_code: window.dfp_network_code || "29658103",

    width: 0,

    height: 0,

    dimensions: [],

    slot: null,

    ad: null,

    id: cuid(),
  }),
  watch: {
    location(newVal) {
      if (newVal && newVal.dfp_sizes) {
        this.render_ad();
      }
    },
    width(newVal) {
      if (newVal) {
        this.$emit("set_width", newVal);
      }
    },
  },

  methods: {
    render_ad() {
      if (window.block_dfp) return;

      const has_email_in_url_exp =
        /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;
      if (has_email_in_url_exp.test(this.location.href)) return;

      this.create_size_list();
      this.set_slots();
      this.push_cmd();
    },

    combine_custom_targetting_params() {
      if (this.page_targetting) {
        var criteria = window.targetting_criteria || {};
        for (var key in criteria) {
          if (!Object.prototype.hasOwnProperty.call(criteria, key)) continue;
          this.page_targetting.kvp[key] = criteria[key];
        }
      }
    },

    create_size_list() {
      const window_width = document.documentElement.clientWidth;

      for (let x = 0; x < this.location.dfp_sizes.length; x++) {
        var _size = this.location.dfp_sizes[x];

        if (window_width > _size.breakpoint) {
          if (!_size.enabled && _size.enabled !== undefined) {
            return false;
          }

          this.unit_name = _size.unit_name;
          this.width = _size.width;
          this.height = _size.height;

          var size_list = "";
          try {
            size_list = JSON.parse(_size.size_list);
          } catch (err) {
            size_list = "";
          }

          if (size_list) {
            var has970x90 = size_list.filter(
              (size) => size[0] === 970 && size[1] === 90
            ).length;
            var has728x90 = size_list.filter(
              (size) => size[0] === 728 && size[1] === 90
            ).length;

            if (has970x90 && !has728x90) {
              size_list.push([728, 90]);
            }
            this.dimensions = size_list;
          } else {
            this.dimensions = [this.width, this.height];
          }
          break;
        }
      }
    },

    set_slots() {
      if (this.unit_name === undefined) {
        return false;
      }

      function insert(str, index, value) {
        return str.substr(0, index) + value + str.substr(index);
      }

      const sidearmextended = this.unit_name.indexOf(
        "sidearmextended/adaptive-1"
      );
      if (
        sidearmextended !== -1 &&
        window.targetting_criteria &&
        window.targetting_criteria.site
      ) {
        this.unit_name = insert(
          this.unit_name,
          sidearmextended + "sidearmextended/".length,
          window.targetting_criteria.site + "/"
        );
      }

      this.slot = "/" + this.network_code + "/" + window.ad_full_unit_name;

      if (this.dimensions[0] === 0 && this.dimensions[1] === 0) return;
    },

    push_cmd() {
      if (!this.slot) {
        return false;
      }

      const is_above_fold =
        this.$refs.ad.getBoundingClientRect().top + window.scrollY <
        window.innerHeight;

      let parent_elements = [];
      let el = this.$refs.ad;
      while (el) {
        parent_elements.unshift(el);
        el = el.parentElement;
      }
      const is_sticky =
        this.name.toLowerCase().indexOf("sticky") > -1 ||
        parent_elements.filter(
          (node) => window.getComputedStyle(node).position === "fixed"
        ).length;

      const position =
        this.location.effect === "pos_middle"
          ? "middle"
          : is_sticky
          ? "sticky"
          : is_above_fold
          ? "top"
          : "bottom";

      window.googletag.cmd.push(() => {
        if (this.page_targetting) {
          this.page_targetting.applyTo(window.googletag.pubads());

          var slot_targetting = this.page_targetting.forUnit(
            this.unit_name.split("/").slice(3).join("/"),
            position,
            null,
            { keyword: [] }
          );

          this.slot = slot_targetting.fullIu;
          this.ad = window.googletag.defineSlot(
            slot_targetting.fullIu,
            this.dimensions,
            this.id
          );
          slot_targetting.applyTo(this.ad);
        } else {
          this.ad = window.googletag.defineSlot(
            this.slot,
            this.dimensions,
            this.id
          );
          var criteria = window.targetting_criteria || {};
          for (var key in criteria) {
            if (!Object.prototype.hasOwnProperty.call(criteria, key)) continue;
            window.googletag.pubads().setTargeting(key, criteria[key]);
          }

          this.ad.setTargeting("pos", position);
          this.ad.setTargeting("location", this.name);
          if (
            this.width === 970 &&
            this.name.toLowerCase().indexOf("sticky") > -1
          ) {
            this.ad.setTargeting("pos2", "sticky970x66");
          }
        }
        this.ad.addService(window.googletag.pubads());

        setTimeout(() => {
          window.googletag.cmd.push(() => window.googletag.enableServices());
          window.googletag.cmd.push(() => {
            window.sidearmsports.gpt.headerBidding.queueForBiddingOrRefreshImmediately(
              this.slot,
              [this.dimensions],
              this.ad,
              this.id
            );
            window.googletag.display(this.id);
          });
        }, 0);
      });
    },

    load_gpt() {
      if (!window.gpt_load_initiated) {
        window.gpt_load_initiated = true;
        var useSSL = "https:" == document.location.protocol;
        var src =
          (useSSL ? "https:" : "http:") +
          "//www.googletagservices.com/tag/js/gpt.js";
        window.googletag = window.googletag || {
          cmd: [],
        };
        window.googletag.cmd.push(function () {
          window.googletag.pubads().disableInitialLoad();
        });
        var script = document.createElement("script");
        script.src = src;
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    },
  },

  created() {
    this.load_gpt();
  },

  mounted() {
    if (this.location && this.location.dfp_sizes) {
      this.combine_custom_targetting_params();

      this.render_ad();
    }

    if (!window.sidearmsports) {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://sidearm-syndication.s3.amazonaws.com/prod/header-bidding-wrapper.bundle.js"
      );
      document.head.appendChild(recaptchaScript);
    }
  },
};
</script>

<style lang="scss" scoped>
.sidearm-dfp-ad__inner {
  margin: 0 auto;
  text-align: center;
}
</style>
