import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Client from "@/client";
import store from "./store";
import moment from "moment";
import VueScreen from "vue-screen";
import VueYouTubeEmbed from "vue-youtube-embed";
import VueFeather from "vue-feather";
import ElementAd from "@/components/elements/ads/ElementAd.vue";
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Navigation,
  Autoplay,
  Controller,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
Vue.use(VueYouTubeEmbed);
Vue.use(VueFeather);
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay, Controller]);
Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(VueScreen, {
  mobile: 0,
  tabletsmall: 768,
  tablet: 1024,
  laptop: 1280,
  desktop: 1440,
  cinema: 1920,
  breakpointsOrder: [
    "mobile",
    "tabletsmall",
    "tablet",
    "laptop",
    "desktop",
    "cinema",
  ],
});
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.prototype.window = window;
Vue.config.devtools = true;
Vue.component("ElementAd", ElementAd);
new Vue({
  data: () => ({
    dark_mode: false,
    block_dfp: true,
    data: null,
    gameday: null,
    roster: [],
    selected_components: [],
    navigation: null,
    active_component: null,
  }),
  methods: {
    get_data_from_api() {
      window.gameday_id = process.env.VUE_APP_GAMEDAY_ID;
      if (!window.gameday_id) {
        return Promise.reject("Failed to get gameday");
      }
      // return Client.get(`/services/signingday.ashx?type=get-signingday&id=8`, {
      return Client.get(
        `/services/gameday.ashx?type=get-gameday&id=${window.gameday_id}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
          },
        }
      ).then((response) => {
        if (response && response.data) {
          return response.data;
        }
        throw new Error("Failed to get signing day.");
      });
    },
    get_data_from_window() {
      return new Promise((resolve, reject) => {
        if (window.gameday) {
          return resolve(window.gameday);
        }
        return reject("Not Found in window");
      });
    },
    get_data() {
      var data;

      if (process.env.VUE_APP_IN_DEV_MODE) {
        data = this.get_data_from_api();
      } else {
        data = this.get_data_from_window();
      }
      data
        .then((data) => {
          if (window.block_dfp != null) this.block_dfp = window.block_dfp;
          this.gameday = data;
          if (
            data.colors &&
            Object.keys(data.colors).length != 0 &&
            data.colors.constructor === Object
          ) {
            var school_colors = data.colors;

            document.documentElement.style.setProperty(
              "--color-primary",
              school_colors.primary_background
            );
            document.documentElement.style.setProperty(
              "--color-safe-text-on-light",
              school_colors.safe_text_white
            );
            document.documentElement.style.setProperty(
              "--color-safe-text-on-dark",
              school_colors.safe_text_black
            );
            document.documentElement.style.setProperty(
              "--text-on-primary",
              school_colors.primary_text
            );
            document.documentElement.style.setProperty(
              "--color-secondary",
              school_colors.secondary_background
            );
            document.documentElement.style.setProperty(
              "--text-on-secondary",
              school_colors.secondary_text
            );
          }

          if (data.selectedComponents && data.selectedComponents.length) {
            this.selected_components = data.selectedComponents.filter(
              (component) => {
                return component.name != "navigation";
              }
            );

            let _navigation = data.selectedComponents.filter((component) => {
              return component.name === "navigation";
            });

            if (_navigation.length) {
              this.navigation = _navigation[0];
            }
          }

          let roster = data.selectedComponents.filter((x) => {
            return x.name === "roster";
          })[0];

          if (roster) {
            let _data = roster.selectedPlayers;
            this.roster = _data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  watch: {},
  created() {
    this.get_data();
  },
  mounted() {},
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

if (process.env.NODE_ENV !== "production") {
  const style = document.createElement("style");
  style.innerHTML = `
          :root {
              --primary-background: 	#C8112E;;
              --primary-text: 		    #FFFFFF;
              --secondary-background: #D9D9DB;
              --secondary-text: 	  	#1C453B;
              --safe-text-on-white: 	#000000;
              --safe-text-on-black: 	#FFFFff;
          }
      `;
  document.head.appendChild(style);
}
